@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Gabarito', cursive;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: 0.7s;
}
a:hover{
  transform: scale(1.025)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.baseText {
  color: #0f206d
}

.fancyTitle {
  color: white;
  font-weight: bolder;
  font-family: 'Gabarito', cursive;
  font-size: 2.25rem;
}


.fancySubtitle {
  color: #4b4b4b;
  font-weight: bolder;
  font-family: 'Gabarito', cursive;
  font-size: 1.5rem;

}

.Logo{
  width: 13rem;
  
}

.MainPage {
  width: 60%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.MainContent {
  width: 90%;
  margin-top: 0.75rem
}

.MainContent p {
  font-size: 1.1rem;
  
}

.Sidebar {
  background-color: #88cc6c;
  color: white;
  width: 40%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1.75rem;
  overflow-y: auto;
  overflow-x: hidden
}

.MainPage, .Sidebar {
  box-sizing: border-box;
}

.service-list {
  list-style-type: none;
  padding: 0;
}

.service-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.service-icon {
  min-width:5%;
  color: #0e4a77;
  font-size: 1rem;
  margin-right: 0.5rem;
}

.service-icon-light{
  min-width:5%;
  color: #fff;
  font-size: 1rem;
  margin-right: 0.5rem;
}

.choiceSection {
  margin-left: -8.1%;
  padding: 2.5rem 4rem;
  position: relative;
  transition: 0.5s ease-out;  
  width: 116.5%;
  background-color: #0a3557;
  color: white;
  box-sizing: border-box;
}

.sliding-off-l{
  left: -100%;
}

.sliding-on-l {
  left: 0
}

.finalSection {
  align-items: 'center';
  gap: 3rem;
  position: relative;
  transition: 0.5s ease-out;  
}

.lightbulb{
  color: #0e4a77;
  font-size: 9rem;
}

.sliding-off-r{
  right: -120%;
}

.sliding-on-r {
  right: 0
}

.bigIcon {
  color: #0a3557;
  font-size: 10rem;
}

.footer {
  bottom: 0;
  width: 100%;
  background-color: #0A5257;
  color: white;
}

.footerRow{
  padding: 0.5rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.footerContent{
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 3rem;
  padding: 2rem
}

.footerCol{
  display: flex;
  flex-direction: column;
  gap: 0.5rem
}

.map{
  width: 15vw;
  height: 8vw;
}

.socialsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.social {
  color: white;
  font-size: 2.5rem;
  transition: 0.7s;
}

.social:hover {
  transform: scale(1.1);
}

/*--------------------------SIDEBAR----------------------------*/
form {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 85%;
  background-color: white;
  border-radius: 1rem;
}

.formInputRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;

}

.formInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  color: #0e4a77;
  font-weight: 700;
  gap: 0.5rem;
}

input[type=text], input[type=email], input[type=tel],textarea {
  width: 100%;
  padding: 1rem;
  border: none;
  border: 2px solid #0e4a77;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #0e4a77;
  box-sizing: border-box;
  font-family: 'Gabarito', cursive;

}

input[type=text]:focus, input[type=email]:focus, input[type=tel]:focus, textarea:focus {
  outline: 2px solid #0e4a77;
}

input[type=checkbox] {
  width:1.5rem;
  /* accent-color: #68BD45; */
  outline: none;
}

.submitButton {
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: #0e4a77;
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem
}



/*----------------------------THANK YOU-----------------------------------*/
.thankYouContainer{
  position:fixed;
  width: 100%;
  height: 100%;
  background-image: url('/public/Background.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thankYouTop{
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  box-sizing: border-box;
}

.thankYouTitle{
  color: #468e56;
  font-size: 4rem;
  margin: 0;
}
.thankYouSubtitle{
  text-align: center;
  color: #337941;
  font-size: 2.5rem;
  margin: 0;
}

.thankYouLogo {
  width: 14rem;
}

.thankYouLogoMobile{
  display: none;
  width: 14rem;
}


.thankYouCredit {
  font-size: 0.8rem;
  color: #fff;
}

.thankYouLinks{
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  display: flex;
  gap: 1.5rem
}

.thankYouIcon{
  
  font-size: 1.75rem;
  color:#0a3557;
  transition: 0.7s;
}

.thankYouIcon:hover{
  transform: scale(1.2);
}


/*-----------MEDIAQUERY*/


@media screen and (min-width: 815px) and ( max-width: 1000px) {
  .fancyTitle {
    font-size: 2rem;
  }

    
  .map{
    width: 100%;
    height: 50%
  }
  

  .footerRow{
    flex-direction: column;
    gap:1rem
  }
  
  .footerContent{
    margin: 0 auto;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
    padding: 2rem
  }
  
}


@media screen and (max-width: 815px) {

  .fancyTitle, .fancySubtitle {
    line-height: 2rem;
  }

  .MainPage {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Sidebar {
    background-color: #88cc6c;
    color: white;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.75rem
  }

  .service-item {
    display: flex;
    align-items: center;
  }

  .footer {
    bottom: 0;
    width: 100%;
    background-color: #0A5257;
    color: white;
  }
  
  .footerCol{
    display: flex;
    flex-direction: column;
    gap: 0.5rem
  }
  
  .footerRow{
    flex-direction: column;
    gap:2rem
  }
  
  .footerContent{
    margin: 0 auto;
    width: 75%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
    padding: 2rem
  }
  
  .map{
    width: 100%;
    height: 50%;
  }
  
  .socialsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .sliding-off-l{
    left: 0;
  }
  
  .sliding-on-l {
    left: 0
  }

  .choiceSection {
    padding: 2rem;
  }

  .finalSection {
    position: relative;
    transition: 0.5s ease-out;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem
  }

  .lightbulb{
    color: #0e4a77;
    font-size: 50px;
  }
  
  .sliding-off-r{
    right: 0;
  }
  
  .sliding-on-r {
    right: 0
  }

}


@media screen and (min-width: 300px) and (max-width: 1200px) {
  /*THANK YOU*/

  .thankYouContainer{
    position:fixed;
    width: 100%;
    height: 100%;
    background-image: url('/public/BackgroundMobile.png');
    background-size: contain;
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .thankYouContent{
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    justify-content: center;
    align-items: center;
  }
  
  .thankYouLogo {
    display: none;
  }
  
  
  .thankYouLogoMobile{
    display: block;
    width: 14rem;
  }
  
  
  .thankYouTitle{
    color: #468e56;
    font-size: 2rem;
    margin: 0.5rem;
  }
  .thankYouSubtitle{
    color: #337941;
    font-size: 1.25rem;
    margin: 0;
  }
  
  .thankYouLinks{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1.5rem
  }
  
  .thankYouIcon{
    font-size: 2.75rem;
    color:#fff;
    transition: 0.7s;
  }
  
}

@media screen and (max-width: 300px) {
   /*THANK YOU*/

   .thankYouContainer{
    position:fixed;
    width: 100%;
    height: 100%;
    background-image: url('/public/BackgroundMobile.png');
    background-size: contain;
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .thankYouContent{
    margin-top: -7rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  
  .thankYouLogo {
    display: none;
  }
  
  
  .thankYouLogoMobile{
    display: block;
    width: 14rem;
  }
  
  
  .thankYouTitle{
    color: #468e56;
    font-size: 2rem;
    margin: 0.5rem;
  }
  .thankYouSubtitle{
    color: #337941;
    font-size: 1.25rem;
    margin: 0;
  }
  
  .thankYouLinks{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7rem;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1.5rem
  }
  
  .thankYouIcon{
    font-size: 2rem;
    color:#fff;
    transition: 0.7s;
  }
}