.App {
  width: 100%;
  height: 100%;
  flex-direction: row;
  gap: 0;
}

.AppReverse {
  display: none;
}


@media screen and (max-width: 815px) {
 .App{
  display: none;
 }

 .AppReverse {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 0;
}

}